<div>
    <div id="carouselExample" class="carousel slide">
        <div class="carousel-inner">
            <ng-container *ngFor="let element of content?.banners; let i = index">
                <div class="carousel-item" [class.active]="i === 0">
                    <img src="assets/images/loading.gif" class="d-block w-100"
                        [attr.data-src]="_fileUploadService.getFileUrl(element, 'media')" [alt]="element.title"
                        (error)="_fileUploadService.onImgError($event, 'media')" appLazyLoad />
                </div>
            </ng-container>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</div>

<section class="services-section pb-100" style="padding: 20px;">
    <h3>New Tractors</h3>
    <ng-container *ngIf="tractorList?.length">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let element of tractorList">
                <app-single-tractor [data]="element"></app-single-tractor>
            </div>
        </div>
    </ng-container>
    <div style="text-align: center;"><a class="btn btn-primary" [routerLink]="'/tractors'">View All</a></div>
</section>

<section class="services-section pb-100" style="padding: 20px;">
    <h3>Used Tractors</h3>
    <ng-container *ngIf="tractorList?.length">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let element of tractorList">
                <app-single-tractor [data]="element"></app-single-tractor>
            </div>
        </div>
    </ng-container>
    <div style="text-align: center;"><a class="btn btn-primary" [routerLink]="'/buy-used-tractors'">View All</a></div>
</section>

<section class="subscribe-section ptb-100">
    <div class="container-fluid pr-0">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 pr-0">
                <!-- <div class="subscribe-image">
                    <div class="main-image">
                        <img [src]="_fileUploadService.getFileUrl(content?.bannerOne, 'media')"
                            [alt]="content?.bannerOne" (error)="_fileUploadService.onImgError($event, 'media')"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="2s" />
                    </div>

                    <div class="subscribe-mobile-image">
                        <img src="assets/images/subscribe/pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                </div> -->

                <div id="aboutCarouselExample" class="carousel slide">
                    <div class="carousel-inner">
                        <ng-container *ngFor="let element of content?.banners; let i = index">
                            <div class="carousel-item" [class.active]="i === 0">
                                <img src="assets/images/loading.gif" class="d-block w-100"
                                    [attr.data-src]="_fileUploadService.getFileUrl(element, 'media')"
                                    [alt]="element.title" (error)="_fileUploadService.onImgError($event, 'media')"
                                    appLazyLoad />
                            </div>
                        </ng-container>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#aboutCarouselExample"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#aboutCarouselExample"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-area-content">
                    <span>About Agriit Solution</span>
                    <h3>About Agriit Solution</h3>
                    <p>Agriit Solution is an online digital platform to buy, sell, finance, insurance, and service
                        new/used
                        tractors and farm implements. Here, you can find all popular brands like John deere, Mahindra,
                        Swaraj, Eicher, Sonalika, New Holland, Powertrac, Farmtrac, etc, on a single platform.</p>
                    <p>We provide information on all the farm-related products for your convenience. Along with this,
                        you
                        can get each agricultural product at a fair price. We have connected with well-established 20+
                        tractor
                        brands. The brands won farmers' hearts by providing quality products at economical prices.</p>
                    <p>We separated everything according to categories at Agriit Solution for your convenience. Want to
                        know more? Here, you get all the detailed information to resolve your query.</p>

                    <div class="support-btn">
                        <a routerLink="/" class="default-btn">Learn More <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="subscribe-section ptb-100">
    <div class="container-fluid pr-0">
        <div class="row align-items-center">

            <div class="col-lg-12 col-md-12 pr-0">
                <div class="subscribe-image">
                    <div class="main-image">
                        <img [src]="_fileUploadService.getFileUrl(content?.bannerTwo, 'media')"
                            [alt]="content?.bannerTwo" (error)="_fileUploadService.onImgError($event, 'media')"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" />
                    </div>

                    <div class="subscribe-mobile-image">
                        <img src="assets/images/subscribe/pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="subscribe-section">
    <div class="container-fluid pr-0">
        <div class="section">
            <h2>New Tractors</h2>
            <p>Now, you can easily buy the newest tractors online! Featuring top brands like John Deere Tractor,
                Mahindra
                Tractor, Swaraj Tractor, Eicher Tractor, Sonalika Tractor, New Holland Tractor, Powertrac Tractor,
                Farmtrac
                Tractor, and more, these models are loaded with the latest features for efficient farming.</p>
            <p>Whether you're a seasoned pro or just starting, our new tractors cater to all needs. You can also find
                essential
                tractor implements like rotavators, cultivators, and more at Agriit Solution. Explore affordable,
                high-quality
                brands for all your farm needs.</p>
        </div>

        <div class="section">
            <h2>Sell Used Tractors</h2>
            <p>Now, you can sell used tractors online without any effort. Just go on, sell the tractor online on Agriit
                Solution
                and fill out the form. After that, our team will help you sell your used tractor.</p>
            <p>We have also introduced tractor valuation to get your used tractor's fair market value. You can sell used
                tractors at a reasonable market price and upload pictures of your used tractor at Agriit Solution free
                of cost.
            </p>
        </div>

        <div class="section">
            <h2>Compare Tractors</h2>
            <p>Easily compare a wide range of tractors on Agriit Solution. Our platform allows you to evaluate
                specifications,
                prices, and features side by side, including power, fuel efficiency, or brand preference, helping you
                make an
                informed decision.</p>
        </div>

        <div class="section">
            <h2>Brand Directory</h2>
            <h3>Tractor Brands</h3>
            <p>Mahindra Tractor/ John Deere Tractor/ Swaraj Tractor/ Sonalika Tractor/ New Holland
                Tractor/ Eicher Tractor/ Escorts\ Kubota/ Tafe Tractor/ Massey Ferguson Tractor/ Force Motors /
                Solis Tractor/ Indo Farm/ Preet / Same Deutz Fahr</p>
        </div>

        <div class="section">
            <h2>New Tractors</h2>
            <p>Mahindra 575, Mahindra 475, Mahindra Yuvo 415, Mahindra 275, Mahindra Yuvo 265, Mahindra Arjun 555, New
                Holland
                5620, New Holland EXCEL 4710, New Holland 3037, Swaraj 744 FE, Swaraj 960 FE, Swaraj 717, John Deere
                5310, John
                Deere 5405, John Deere 5065 E 4WD, Sonalika DI 35, Massey Ferguson 1030 DI Mahashakti, Eicher 5660,
                Force Motors
                Balwan 500, Kubota MU5501, Solis 5015.</p>
        </div>

        <div class="section">
            <h2>Tractor Models</h2>
            <p>Mahindra Yuvo 575, Mahindra 585 DI Sarpanch, Mahindra Arjun NOVO 605 DI-Ms, John Deere 5310, Swaraj 735
                XT,
                Sonalika DI 734, Massey Ferguson 9500 4WD, Eicher 480.</p>
        </div>

        <div class="section">
            <h2>Implement Brands</h2>
            <p>Shaktiman/ Lemken/ Mahindra/ Tafe/ Sonalika/ New Holland/ Kubota / Indo
                Farm/ Preet/ Kartar/ Dasmesh / Deccan farm/ Landforce/ Sai/ Khedut/ Class</p>
        </div>

        <div class="section">
            <h2>Implement Types</h2>
            <p>Baler, Combine Harvester, Cultivator, Disc Harrow, Hole Digger, Potato Harvester, Seed Drill, Sprayer,
                Straw
                Reaper, Sugarcane Harvester.</p>
        </div>

        <div class="section">
            <h2>Tractors by HP</h2>
            <p>0-20 HP, 21-30 HP, 31-40 HP, 41-50 HP, 51-60 HP, 61-70 HP, 71-80 HP, 81-90 HP, 91-100 HP.</p>
        </div>

        <div class="section">
            <h2>New Tractors & Implements</h2>
            <p>We all know that tractors are nothing without their attachments. Whether they are tractor implements like
                rotavators or cultivators, without them, the tractor is just a transport vehicle. Agriit Solution
                provides you
                with all the implements with their specifications and complete information related to your farm
                necessities.</p>
        </div>

        <div class="section">
            <h2>Used Tractors & Implements</h2>
            <p>Now you can buy or sell used tractors and implements online effortlessly. Just fill out the form on the
                Agriit
                Solution platform, and our team will assist you. You can sell your used tractor and implements at a fair
                market
                price and upload pictures on our platform.</p>
        </div>
    </div>
</section>

<!-- <section class="subscribe-section ptb-100">
    <div class="container-fluid pr-0">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-area-content">
                    <span>Email address</span>
                    <h3>Subscribe our monthly newsletter to get updated</h3>
                    <p>Don’t be afraid your mail is secure it no will be shared anywhere or everywhere.</p>

                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Submit now <i class="fa fa-arrow-right"></i></button>
                    </form>

                    <ul class="social-list">
                        <li class="list-heading">Follow us for update:</li>
                        <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="subscribe-image">
                    <div class="main-image">
                        <img [src]="_fileUploadService.getFileUrl(content?.bannerThree, 'media')"
                            [alt]="content?.bannerThree" (error)="_fileUploadService.onImgError($event, 'media')"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" />
                    </div>

                    <div class="subscribe-mobile-image">
                        <img src="assets/images/subscribe/pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->